<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>Add Process</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @keyup.enter="validator();submit()">
                            <CRow>
                                <CCol sm="4">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>


                                <CCol sm="2">
                                    <CSelect
                                        v-model="form.is_gpt_task.val"
                                        :description="form.is_gpt_task.inv"
                                        label="Use ChatGPT"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_gpt_task.val=$event.target.value"
                                    />
                                </CCol>

                                <CCol sm="2" v-show="show == 1">
                                    <label>Input Data</label>
                                    <model-select :options="input_datas" v-model="form.input_data_id.val" placeholder="Please Select">
                                    </model-select>
                                    <small class="form-text text-muted w-100">{{form.input_data_id.inv}}</small>
                                </CCol>

                                <CCol sm="2"  v-show="show == 1">
                                    <CInput v-model="form.data_precision.val" :description="form.data_precision.inv" label="Precision" />
                                </CCol>

                                <CCol sm="2">
                                    <CSelect
                                        v-model="form.is_active.val"
                                        :description="form.is_active.inv"
                                        label="State"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_active.val=$event.target.value"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <CInput v-model="form.sort_num.val" :description="form.sort_num.inv" label="Display Priority" />
                                </CCol>
                              



                                <CCol sm="2">

                                    <CSelect
                                        v-model="form.log_output_data.val"
                                        :description="form.log_output_data.inv"
                                        label="Log Output Data"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.log_output_data.val=$event.target.value"
                                    />

                                </CCol>

                                <CCol sm="6">
                                    <CInput v-model="form.detail.val" :description="form.detail.inv" label="Description"/>
                                </CCol>

                            </CRow>
                            <CRow>

                                <CCol sm="12">
                                    <label>Process Pattern</label>
                                    <CTextarea id="editor" class="equation" />
                                    <small class="form-text text-muted w-100">{{form.proccess.inv}}</small>
                                </CCol>
                                   
                            </CRow>
                        </CForm>
                        <br>
                        <div class="form-group form-actions">
                            <CButton @click="form.proccess.val=editor.getDoc().getValue();validator();submit()" color="primary"> Submit</CButton>
                        </div>    
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
import * as CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/xq-light.css'
import 'codemirror/mode/python/python'
import 'codemirror/addon/edit/matchbrackets'
import 'codemirror/addon/edit/matchtags'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/closetag'
export default {
    components: { ModelSelect },
    mounted(){
        this.get_input_datas();
        this.editor = CodeMirror.fromTextArea(document.getElementById('editor'), {
            line: false,
            theme: 'xq-light',
            mode: 'python',
            matchBrackets: true,
            autoCloseBrackets: true,
            matchTags: true,
            autoCloseTags: true,
        });
        this.editor.setSize(null, 50)
    },
    data() {
        return {
            form: {
                name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                input_data_id: {val: '', inv: '', v: { req: true, max: 1024 }},
                proccess: {val: '', inv: '', v: { req: true, max: 1024 }},
                is_active: {val: '', inv: '', v: { req: true, max: 1024 }},
                data_precision: {val: '', inv: '', v: { req: true, max: 2048 }},
                sort_num: {val: '', inv: '', v: { req: true, max: 2048 }},
                detail: {val: '', inv: '', v: { req: true, min: 2, max: 2048 }},
                is_gpt_task: {val: '', inv: '', v: { req: true, max: 1024 }},
                log_output_data: {val: '', inv: '', v: { req: true, max: 1024 }},
            },
            show: 1,
            input_datas: [{value: '0', text: 'Trigger by Specific Data'}],
            disable: false,
            editor: null,
        }
    },
    watch: {
        'form.is_gpt_task.val':{
            handler(){
                if(this.form.is_gpt_task.val == 'true'){
                    this.show = 0;
                } else {
                    this.show = 1;
                }
            }        
        }
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {


                if(this.form.is_gpt_task.val == 'true'){
                    if(key == 'input_data_id'){
                        this.form[key].v.req = false;
                    }

                    if(key == 'data_precision'){
                        this.form[key].v.req = false;
                    }
                    
                }


                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if(this.form[key].v.req == true){
                                if (this.form[key].val == '') {
                                    this.form[key].inv = 'This field is required.';
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'min':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length < this.form[key].v[k]) {
                                    this.form[key].inv = `Must be at least ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        get_input_datas(){
            this.axios.get('arbitrage/input_datas/')
                .then(res => {
                    res.data.map(val => {
                        this.input_datas.push({value: val.id, text: val.name});
                    });
                })  
        },
        submit(){
            if(this.disable == false){
                const data = new FormData();
                for (const key in this.form){
                    data.set(key, this.form[key].val);
                }
                this.axios.post('arbitrage/input_proccesses/', data)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Submitted Successfully.'
                        });
                        this.$router.push('/data/proccesses');
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    })
            }
        },
    }
}
</script>
